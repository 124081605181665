import apiCall from "../utils/apiCall";


export const fetchCategories = async(data) => {
    try {
        const response = await apiCall('get','/categories');
        return response.data;
    } catch (error) {
        console.error('Failed to register user:', error);
        throw error;
    }
}

export const fetchSkills = async(data) => {
    try {
        const response = await apiCall('get','/skills/skill');
        return response.data;
    } catch (error) {
        console.error('Failed to sign in user:', error);
        throw error;
    }
}
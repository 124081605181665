import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import Beautifier from "../../../../legacy/components/common/beautifier";
import useExperimentStore from "../../../store/experimentStore";
import classNames from "../../../utils/ClassNames";
import Button from "../../../components/Buttons/Buttons";
import { inviteExistingReferenceToAssessment } from '../../../services/candidateService';
import useGlobalStore from '../../../store/globalStore';
import Link from '../../../components/Link/Link';
import { P } from '../../../components/Typography/Typography';

const statuses = {
    'NotInvited': 'text-gray-700 bg-gray-50 ring-gray-600/20',
    'Pending': 'text-yellow-600 bg-yellow-50 ring-yellow-500/10',
    'Complete': 'text-green-600 bg-green-50 ring-green-500/10',
};

export default function WorkUserReferences({ workId, sentInvitationsLength }) {
    const { findReferencesByWorkHistoryId, findReferencesByWorkHistoryIdWithStatus, sentInvitations, findJobById, addNewSentInvitation } = useExperimentStore(state => state);
    const { setAppNotificationWithTimeout, setNotification, setNotificationStatus, notification } = useGlobalStore((state) => state);

    let { id } = useParams();
    const references = findReferencesByWorkHistoryIdWithStatus(workId, id) || [];

    const [isExpanded, setIsExpanded] = useState(false);
    const visibleReferences = isExpanded ? references : references.slice(0, 3);
    const invitedReferences = sentInvitations.filter(sentInvitation => {
        //console.log('Checking job ID:', job.id, 'against', work_history_id);
        return sentInvitation.job_candidate_id === id; // Compare with the extracted work_history_id
    });
    const job = findJobById(id);
    const onInviteUser = async (reference) => {
        console.log("invite")
        console.log(reference);
        console.log(findJobById(id))
        const jobData = findJobById(id);
        const response = await inviteExistingReferenceToAssessment({
            "job_candidate_id": id,
            "reference_id": reference.id
        });

        if (!response.success && !response.error) {

            setAppNotificationWithTimeout('Adding reference failed.', 'warning', 'You have already invited the user.', 3000); // 3 seconds
        } else if (response.success) {
            const referenceInvitation = {
                "id": response.data.id,
                "reference_id": reference.id,
                "reference_first_name": reference.reference_first_name,
                "reference_last_name": reference.reference_last_name,
                "reference_email": reference.reference_email,
                "status": "pending",
                "job_id": jobData.job_id,
                "job_name": jobData.job_name,
                "job_description": jobData.description,
                "job_company_name": jobData.company_name,
                "job_location": jobData.location,
                "expiration_date": jobData.expiration_date,
                "assessment_id": jobData.assessment_id,
                "assessment_name": jobData.assessment_name,
                "assessment_description": jobData.assessment_description,
                "recruiter_first_name": jobData.recruiter_first_name,
                "recruiter_last_name": jobData.recruiter_last_name,
                "recruiter_email": jobData.recruiter_email,
            }
            addNewSentInvitation(response.data);
            setAppNotificationWithTimeout('Reference Invited.', 'success', 'Reference invited successfully', 3000); // 3 seconds

        }
    }

    return (
        <div>
            {/* <Beautifier values={sentInvitations}/> */}

            <div className="-mx-4 mt-2 ring-gray-300 sm:mx-0 sm:rounded-lg">
                {references.length === 0 ? (
                    <div className="text-center py-20 min-h-40">
                        <h3 className="mt-2 text-sm font-semibold text-gray-900">
                            No References added to this Experience Entry
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                            Add a reference to this experience. This instantly invites your new reference users.
                        </p>
                    </div>
                ) : (
                    <>
                        <ul className="divide-y divide-gray-200 gap-4">
                            {visibleReferences.map((history) => (
                                <li key={history.id} className="flex items-center justify-between gap-x-6 py-2">
                                    <div className="min-w-0">
                                        <div className="flex items-start gap-x-3">
                                            <p className="text-sm font-semibold leading-6 text-gray-900">
                                                {history.reference_first_name} {history.reference_last_name}
                                            </p>
                                        </div>
                                        <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                            <p className="truncate">Relationship: {history.role} {history.status}</p>
                                        </div>
                                        {/* {JSON.stringify(history)} */}
                                        {/* <p>Assessment ID: {job.assessment_id}</p>
                                        <p>Job Candidate Id: {job.id}</p>
                                        <p>History: {history.id}</p>
                                        <Beautifier value={history}/> */}
                                        {/* <Route path="assessments/:assessmentId/:jobCandidateId/:invitationId" element={<ProtectedRoute allowedRoles={['candidate']}><CandidateLayout><AssessmentTest /></CandidateLayout></ProtectedRoute>} />                     */}


                                        {/* {
                                            history.isInSentInvitations &&
                                            <Link to={`/candidate/assessments/${job.assessment_id}/${job.id}/${history.id}`}>
                                                Start Test
                                            </Link>
                                        } */}

                                    </div>
                                    <div className="flex flex-none items-center gap-x-4">
                                        {history.isInSentInvitations ? (
                                            <p
                                                className={classNames(
                                                    statuses[history.status==='pending' ? "Pending" : history.status==='complete' ? "Complete" : "NotInvited"],
                                                    'mt-0.5 whitespace-nowrap rounded-md px-3 py-1.5 text-base font-medium ring-1 ring-inset',
                                                )}
                                            >
                                                 {history.isInSentInvitations && "invited" } {history.status}
                                            </p>
                                        ) : (
                                            <>
                                            {
                                              sentInvitationsLength<5 ? <Button
                                                variant="primary"
                                                size="small"
                                                className={""}
                                                type="button"
                                                onClick={() => onInviteUser(history)}
                                                disabled={invitedReferences.length === 5}

                                            >
                                                Invite<span className="sr-only">, {history.name}</span>
                                            </Button>  
                                            : <P className={"text-gray-400"}>Maximum Invitations sent</P>}
                                            </>
                                        )}
                                    </div>
                                </li>
                            ))}
                        </ul>
                        {references.length > 3 && (
                            <div className="text-center mt-4">

                                <Button
                                    variant="secondary"
                                    size="medium"
                                    className={""}
                                    type="button"
                                    onClick={() => setIsExpanded(!isExpanded)}

                                >
                                    {isExpanded ? 'Show Less' : 'Show More'}
                                </Button>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

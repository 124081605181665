
import ContainerNarrow from "../../../components/Containers/ContainerNarrow";
import Link from "../../../components/Link/Link";
import { HorizontalListWithItems } from "../../../components/List/List";
import { H1 } from "../../../components/Typography/Typography";
import CTASection from "./Homepage_CTA";
import FAQ from "./Homepage_FAQ";
import Features from "./Homepage_Features";
import Hero from "./Homepage_Hero";
import Footer from "./Homepage_Footer";

import PeerAssessmentResults from "../../Recruiter/Jobs/Heatmap";

function Homepage() {
    return <>
        <Hero />
        <Features /> 
        <CTASection />
        <FAQ />
        <Footer />
        {/* <Link to="/design">Design System</Link>
        <Link to="/login">Login</Link>
        <Link to="/register">Register</Link>
        <Link to="/candidate">Candidate Area</Link>
        <Link to="/recruiter">Recruiter Area</Link> */}
        </>
}

export default Homepage;
import Badge from '../../../components/Badge/Badge';
import useCandidateStore from '../../../store/candidateStore';
import { H3 } from '../../../components/Typography/Typography';
import useExperimentStore from '../../../store/experimentStore';
import Beautifier from '../../../../legacy/components/common/beautifier';
import Link from '../../../components/Link/Link';
import { Input } from '../../../components/Form/Form';


const SentInvitationItem = ({ candidate, onDelete, onEdit, job }) => {
    const expiration_date = new Date(candidate.expiration_date).toLocaleString('en-US', {
        dateStyle: 'medium', // Adjust the date style as needed (e.g., 'full', 'medium', 'long')
        timeStyle: 'short' // Adjust the time style as needed (e.g., 'full', 'medium', 'long')
    });
    let status = "complete" //candidate.status;
    return (
        <tr key={candidate.candidate_id}>
            <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                {candidate.reference_first_name} {candidate.reference_last_name}
                <Link to={`/candidate/assessments/${candidate.assessment_id}/${candidate.job_candidate_id}/${candidate.id}`}>
                    Start Test
                </Link>
                <div className="mt-1 flex flex-col text-gray-500">
                    <span>{candidate.reference_email}</span>
                </div>
                <div className="mt-1 flex flex-col text-gray-500 sm:hidden">

                    <span>{candidate.job_name}</span>
                    <span>{candidate.assessment_name}</span>
                    <span>
                        <Badge
                            text={candidate.status === "pending" ? "pending" : candidate.status === "expired" ? "expired" : "complete"}
                            color={candidate.status === "pending" ? "blue" : candidate.status === "expired" ? "red" : "green"}
                        />
                    </span>
                </div>
            </td>
            <td className="hidden px-3 py-3.5 text-sm text-gray-500 md:table-cell">
                <div className="mt-1 flex flex-col text-gray-500">
                    <span className='font-bold text-sm'>{candidate.job_name}</span>
                    <span>{candidate.assessment_name}</span>
                    <span>{expiration_date}</span>
                </div>
            </td>
            <td className="hidden px-3 py-3.5 text-sm text-gray-500 md:table-cell">
                <Badge
                    text={candidate.status === "pending" ? "pending" : candidate.status === "expired" ? "expired" : "complete"}
                    color={candidate.status === "pending" ? "blue" : candidate.status === "expired" ? "red" : "green"}
                />
            </td>
        </tr>
    );
};

export default function SentInvitationComponent({ candidates, onDelete, onEdit, jobItem }) {
    const {
        sentInvitations,
    } = useExperimentStore((state) => state);

    return (
        <div className="-mx-4 mt-2 ring-gray-300 sm:mx-0 sm:rounded-lg">
            <H3>Sent Invitations</H3>
            {
                sentInvitations.length === 0
                    ? <div className="text-center py-20 min-h-40">
                        <h3 className="mt-2 text-sm font-semibold text-gray-900">No sent invitations</h3>
                        <p className="mt-1 text-sm text-gray-500">You have not invited anybody to a peer-assessment. Invitations will appear here.</p>
                    </div>
                    :
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                            <tr>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Name
                                </th>
                                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                                    Invitation
                                </th>
                                <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell">
                                    Status
                                </th>
                            </tr>
                        </thead>
                        <tbody className='divider divide-y divide-x'>

                            {sentInvitations.filter((sentInvitation) => {
                                // Create a Date object from expiration_date for accurate comparison
                                const expirationDate = new Date(sentInvitation.expiration_date);

                                // Get today's date without time (to avoid time zone issues)
                                const today = new Date();
                                today.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to 0

                                // Check if expiration date is after today (not yet passed)
                                return expirationDate >= today;
                            }).map((sentInvitation, index) => (
                                <SentInvitationItem
                                    key={sentInvitation.id}
                                    candidate={sentInvitation}
                                    onDelete={onDelete}
                                    onEdit={onEdit}
                                />
                            ))}
                        </tbody>
                    </table>
            }
            {/* <form onSubmit={editingWorkHistory ? handleSubmit9(onEditWorkHistory) : handleSubmit9(onAddWorkHistory)} className="space-y-8 bg-white p-8 rounded-lg ">
                    <Input
                        label="Company Name"
                        id="company_name"
                        register={register9}
                        required={true}
                        errors={errors9}
                        type="text"
                    />
                    </form> */}
        </div>
    );
};
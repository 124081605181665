import { create } from 'zustand';

const useRecruiterStore = create((set, get) => ({
  user: null,

  assessments: [],
  assessmentSkills: [],
  assessmentQuestions: [],
  assessmentRequirements: [],
  jobs: [],
  jobCandidates: [],

  editingAssessmentSkill: null,
  editingAssessmentRequirement: null,
  editingAssessmentQuestion: null,

  // Legacy Functions


  // Set assessments (e.g., from an API response)
  addAssessment: (newAssessments) => set((state) => ({
    assessments: [
      ...state.assessments,
      ...newAssessments.filter(
        newAssessment => !state.assessments.some(
          assessment => assessment.id === newAssessment.id
        )
      )
    ]
  })),

  // Add a single new assessment
  addNewAssessment: (newAssessment) => set((state) => ({
    assessments: [...state.assessments, newAssessment]
  })),

  editAssessment: (editedAssessment) => set((state) => ({
    assessments: state.assessments.map(assessment =>
      assessment.id === editedAssessment.id ? editedAssessment : assessment
    )
  })),

  canDeleteAssessment: (assessmentId) => {
    const { assessmentRequirements, assessmentQuestions, jobs } = get();
    
    const hasRelatedSkills = assessmentRequirements.some(skill => skill.assessment_id === assessmentId);
    const hasRelatedQuestions = assessmentQuestions.some(question => question.assessment_id === assessmentId);
    const isAssessmentUsedInJobs = jobs.some(job => job.assessment_id === assessmentId);
  
    return !(hasRelatedSkills || hasRelatedQuestions || isAssessmentUsedInJobs);
  },
  
  

  removeAssessment: (assessmentId) => set((state) => ({
    assessments: state.assessments.filter(assessment => assessment.id !== assessmentId)
  })),

  // Assessment Skills interactions
  addAssessmentSkill: (newSkills) => set((state) => ({
    assessmentSkills: [
      ...state.assessmentSkills,
      ...newSkills.filter(
        newSkill => !state.assessmentSkills.some(
          assessment => assessment.id === newSkill.id
        )
      )
    ]
  })),

  // Add a single new assessment skill
  addNewAssessmentSkill: (newAssessmentSkill) => set((state) => ({
    assessmentSkills: [...state.assessmentSkills, newAssessmentSkill]
  })),

  editAssessmentSkill: (editedAssessmentSkill) => set((state) => ({
    assessmentSkills: state.assessmentSkills.map(skill =>
      skill.id === editedAssessmentSkill.id ? editedAssessmentSkill : skill
    )
  })),

  canDeleteAssessmentSkill: (assessmentId) => {
    const { jobs } = get();
    const isAssessmentUsedInJobs = jobs.some(job => job.assessment_id === assessmentId);
    return !(isAssessmentUsedInJobs);
  },

  countAssessmentQuestions: (assessmentId) => {
    const { assessmentQuestions } = get();
    const assessmentCount = assessmentQuestions.filter(question => question.assessment_id === assessmentId).length;
    return assessmentCount;
  },

  countAssessmentRequirements: (assessmentId) => {
    const { assessmentRequirements } = get();
    const assessmentCount = assessmentRequirements.filter(requirement => requirement.assessment_id === assessmentId).length;
    return assessmentCount;
  },


  countAssessmentJobs: (assessmentId) => {
    const { jobs } = get();
    const assessmentCount = jobs.filter(job => job.assessment_id === assessmentId).length;
    return assessmentCount;
  },

  removeAssessmentSkill: (skillId) => set((state) => ({
    assessmentSkills: state.assessmentSkills.filter(skill => skill.id !== skillId)
  })),

  // Jobs interactions
  addJob: (newJobs) => set((state) => ({
    jobs: [
      ...state.jobs,
      ...newJobs.filter(
        newJob => !state.jobs.some(
          job => job.id === newJob.id
        )
      )
    ]
  })),
  addNewJob: (newJob) => set((state) => ({
    jobs: [...state.jobs, newJob]
  })),
  editJob: (editedJob) => set((state) => ({
    jobs: state.jobs.map(job =>
      job.id === editedJob.id ? editedJob : job
    )
  })),

  canDeleteJob: (jobId) => {
    const { jobCandidates } = get();
    // Filter jobCandidates based on jobId)
    const filteredCandidates = jobCandidates.filter(candidate => candidate.job_id === jobId);
    // Check if any filtered candidates have 'in-progress' or 'pending' status
    const isJobUsed = filteredCandidates.some(candidate => candidate.status === 'in-progress' || candidate.status === 'pending');
    return !isJobUsed;
  },



  removeJob: (jobId) => set((state) => ({
    jobs: state.jobs.filter(job => job.id !== jobId)
  })),

  addAssessmentIdToJob: (editedJob) => set((state) => ({
    jobs: state.jobs.map(job => (
      job.id === editedJob.id ? editedJob : job
    ))
  })),


  // Job Candidates interactions
  addJobCandidate: (newJobCandidates) => set((state) => ({
    jobCandidates: [
      ...state.jobCandidates,
      ...newJobCandidates.filter(
        newJobCandidate => !state.jobCandidates.some(
          jobCandidate => jobCandidate.id === newJobCandidate.id
        )
      )
    ]
  })),
  addNewJobCandidate: (newJobCandidate) => set((state) => ({
    jobCandidates: [...state.jobCandidates, newJobCandidate]
  })),


  editJobCandidate: (editedJobCandidates) => set((state) => ({
    jobCandidates: state.jobCandidates.map(candidate =>
      candidate.id === editedJobCandidates.id ? editedJobCandidates : candidate
    )
  })),

  deleteJobCandidate: (candidateId) => set((state) => ({
    jobCandidates: state.jobCandidates.filter(candidate => candidate.id !== candidateId)
  })),

  countJobCandidates: (jobId) => {
    const { jobCandidates } = get();
    const jobCandidateCount = jobCandidates.filter(candidate => candidate.job_id === jobId).length;
    return jobCandidateCount;
  },

  findItemById: (id, arrayName) => {
    const state = useRecruiterStore.getState();
    return state[arrayName]?.find(item => item.id === id) || null;
  },

  findAssessmentSkillsByAssessmentId: (assessment_id) => {
    const state = useRecruiterStore.getState();
    return state.assessmentSkills.filter(item => item.assessment_id === assessment_id);
  },

  findAssessmentSkillsById: (id) => {
    const state = useRecruiterStore.getState();
    //console.log(state.assessmentSkills.filter(item => item.assessment_id === assessment_id))
    return state.assessmentSkills.filter(item => item.id === id);
  },

  findAssessmentById: (id) => {
    const state = useRecruiterStore.getState();
    //console.log(state.assessmentSkills.filter(item => item.assessment_id === assessment_id))
    return state.assessments.filter(item => item.id === id);
  },


  findJobsWithAssessmentId: ({ id }) => {
    const { jobs = [] } = get(); // Set a default value for jobs if it's null/undefined
    const isAssessmentUsedInJobs = jobs ? jobs.filter(job => job?.assessment_id === id) : [];
    return isAssessmentUsedInJobs;
  },

  setEditingAssessmentSkill: (skill) => set({ editingAssessmentSkill: skill }),

  setEditingAssessmentRequirement: (requirement) => set({ editingAssessmentRequirement: requirement }),

  setEditingAssessmentQuestion: (question) => set({ editingAssessmentQuestion: question }),

  getJobById: ({ id }) => {
    const { jobs } = get();
    return jobs.find(job => job.id === id);
  },

  getCandidateByJobAndCandidateId: ({ jobId, candidateId }) => {
    const { jobCandidates } = get();
    return jobCandidates.find(candidate => candidate.job_id === jobId && candidate.candidate_id === candidateId);
  },

  clearRecruiter: () => set({ user: null, assessments: [], assessmentSkills: [], jobs: [], jobCandidates: [] }),

  // Set assessments (e.g., from an API response)
  addAssessmentQuestion: (newAssessments) => set((state) => ({
    assessmentQuestions: [
      ...state.assessmentQuestions,
      ...newAssessments.filter(
        newAssessment => !state.assessmentQuestions.some(
          assessment => assessment.id === newAssessment.id
        )
      )
    ]
  })),

  // Add a single new assessment
  addNewAssessmentQuestions: (newAssessment) => set((state) => ({
    assessmentQuestions: [...state.assessmentQuestions, newAssessment]
  })),

  editAssessmentQuestion: (editedAssessmentQuestion) => set((state) => ({
    assessmentQuestions: state.assessmentQuestions.map(question =>
      question.id === editedAssessmentQuestion.id ? editedAssessmentQuestion : question
    )
  })),

  removeAssessmentQuestion: (skillId) => set((state) => ({
    assessmentQuestions: state.assessmentQuestions.filter(question => question.id !== skillId)
  })),

  findAssessmentQuestionsByAssessmentId: (assessment_id) => {
    const state = useRecruiterStore.getState();
    return state.assessmentQuestions.filter(item => item.assessment_id === assessment_id);
  },

  // Set assessments (e.g., from an API response)
  addAssessmentRequirements: (newAssessments) => set((state) => ({
    assessmentRequirements: [
      ...state.assessmentRequirements,
      ...newAssessments.filter(
        newAssessment => !state.assessmentRequirements.some(
          assessment => assessment.id === newAssessment.id
        )
      )
    ]
  })),

  // Add a single new assessment
  addNewAssessmentRequirements: (newAssessment) => set((state) => ({
    assessmentRequirements: [...state.assessmentRequirements, newAssessment]
  })),

  editAssessmentRequirement: (editedAssessmentRequirement) => set((state) => ({
    assessmentRequirements: state.assessmentRequirements.map(requirement =>
      requirement.id === editedAssessmentRequirement.id ? editedAssessmentRequirement : requirement
    )
  })),

  removeAssessmentRequirement: (requirementId) => set((state) => ({
    assessmentRequirements: state.assessmentRequirements.filter(requirement => requirement.id !== requirementId)
  })),


  findAssessmentRequirementsByAssessmentId: (assessment_id) => {
    const state = useRecruiterStore.getState();
    return state.assessmentRequirements.filter(item => item.assessment_id === assessment_id);
  },
}));

export default useRecruiterStore;
import Footer from "../components/Footer/Footer";
import CandidateNavbar from "../components/Navbars/candidateNavbar/CandidateNavbar";
import useUserStore from "../store/userStore";
import ContainerFull from "../components/Containers/ContainerFull";


const AdminLayout = ({ children }) => {
    return <>
        <CandidateNavbar />
            <ContainerFull>
                <div className="mx-auto max-w-7xl sm:px-6 lg:px-8 min-h-screen">{children}</div>
            </ContainerFull>
        <Footer />
    </>
}

export default AdminLayout;
import React from 'react';

const HeatmapTable = ({ transformedData }) => {
  // Data from transformedData
  const categories = transformedData.labels || []; // Use labels from transformed data
  const evaluators = ['Overall', 'Subordinates', 'Managers', 'Coworkers']; // Define evaluators based on roles

  // Helper function to get scores for each category
  const getScores = (role) => {
    return transformedData.scores[role.toLowerCase()] || []; // Extract scores based on role
  };

  // Helper function to get color based on score
  const getColor = (score) => {
    if (score === 0 || score === undefined) return 'bg-gray-200'; // Light gray for missing or zero scores
    if (score >= 4.5) return 'bg-teal-600'; // Dark teal for scores >= 4.5 (out of 5)
    if (score >= 4) return 'bg-teal-400'; // Lighter teal for scores 4 - 4.49
    if (score >= 3.5) return 'bg-teal-300'; // Light teal for scores 3.5 - 3.99
    if (score >= 3) return 'bg-yellow-400'; // Yellow for scores 3 - 3.49
    return 'bg-red-400'; // Red for scores below 3
  };

  return (
    <div className="p-4">
      <h2 className="text-lg font-semibold mb-4">Psychometric Scorecard</h2>
      <div className="overflow-x-auto">
        {/* Use 'table-fixed' to enforce fixed column widths */}
        <table className="min-w-full table-fixed border-separate border-spacing-2">
          <thead>
            <tr>
              <th className="p-2 text-left bg-gray-200 w-32">Traits</th>
              {evaluators.map((evaluator, idx) => (
                <th key={idx} className="p-2 text-center bg-gray-200 w-16">
                  <div className="transform rotate-0 origin-bottom text-sm">{evaluator}</div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {categories.map((category, rowIndex) => (
              <tr key={`row-${rowIndex}`}>
                {/* Category Name */}
                <td className="p-2 text-left bg-gray-100 w-32">{category}</td> {/* Fixed width for category cells */}

                {/* Cells for each evaluator */}
                {evaluators.map((evaluator, colIndex) => {
                  const scores = getScores(evaluator);
                  const score = scores[rowIndex]; // Use score directly
                  
                  return (
                    <td
                      key={`cell-${rowIndex}-${colIndex}`}
                      className={`w-16 h-16 ${getColor(score)} rounded-md`} // Apply color based on score
                    >
                      {/* Content intentionally left empty */}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default HeatmapTable;


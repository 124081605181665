import apiCall from "../utils/apiCall";

export const fetchAssessments = async (id) => {
    try {
        const response = await apiCall('get', `/assessments/tests/owner/${id}`);
        console.log(response);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch assessments:', error);
        throw error;
    }
}

export const createAssessment = async (data) => {
    try {
        const response = await apiCall('post', '/assessments/tests/create', data);
        return response.data;
    } catch (error) {
        console.error('Failed to create assessment:', error);
        throw error;
    }
}

export const updateAssessment = async (data) => {
    try {
        const response = await apiCall('put', `/assessments/tests/assessment/${data.id}`, data);
        return response.data;
    } catch (error) {
        console.error('Failed to update assessment:', error);
        throw error;
    }
}

export const deleteAssessment = async (data) => {
    try {
        const response = await apiCall('delete', `/assessments/tests/assessment/${data.id}`, data);
        return response;
    } catch (error) {
        console.error('Failed to delete assessment:', error);
        throw error;
    }
}


// Assessment Requirements
export const fetchAssessmentRequirements = async (id) => {
    try {
        const response = await apiCall('get', `/assessments/requirements/owner/${id}`);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch assessment requirements:', error);
        throw error;
    }
}

export const createAssessmentRequirement = async (data) => {
    try {
        const response = await apiCall('post', '/assessments/requirements/create', data);
        return response;
    } catch (error) {
        console.error('Failed to create assessment requirement:', error);
        throw error;
    }
}

export const updateAssessmentRequirement = async (data) => {
    try {
        const response = await apiCall('put', `/assessments/requirements/requirement/${data.id}`, data);
        return response;
    } catch (error) {
        console.error('Failed to update assessment requirement:', error);
        throw error;
    }
}

export const deleteAssessmentRequirement = async (data) => {
    try {
        const response = await apiCall('delete', `/assessments/requirements/requirement/${data.id}`, data);
        return response;
    } catch (error) {
        console.error('Failed to delete assessment requirement:', error);
        throw error;
    }
}

// Assessment Skill
export const createAssessmentSkill = async (data) => {
    try {
        const response = await apiCall('post', '/skillassessments/create', data);
        return response.data;
    } catch (error) {
        console.error('Failed to create assessment skill:', error);
        throw error;
    }
}

export const updateAssessmentSkill = async (data) => {
    try {
        const response = await apiCall('put', '/skillassessments/update', data);
        return response.data;
    } catch (error) {
        console.error('Failed to update assessment skill:', error);
        throw error;
    }
}

export const deleteAssessmentSkill = async (data) => {
    try {
        const response = await apiCall('delete', '/skillassessments/delete', data);
        return response;
    } catch (error) {
        console.error('Failed to delete assessment skill:', error);
        throw error;
    }
}

// Assessment Questions
export const fetchAssessmentQuestions = async (id) => {
    try {
        const response = await apiCall('get', `/assessments/questions/owner/${id}`);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch assessment questions:', error);
        throw error;
    }
}

export const createAssessmentQuestion = async (data) => {
    try {
        const response = await apiCall('post', '/assessments/questions/create', data);
        return response;
    } catch (error) {
        console.error('Failed to create assessment question:', error);
        throw error;
    }
}

export const updateAssessmentQuestion = async (data) => {
    try {
        const response = await apiCall('put', `/assessments/questions/question/${data.id}`, data);
        return response;
    } catch (error) {
        console.error('Failed to update assessment question:', error);
        throw error;
    }
}

export const deleteAssessmentQuestion = async (data) => {
    try {
        const response = await apiCall('delete', `/assessments/questions/question/${data.id}`, data);
        return response;
    } catch (error) {
        console.error('Failed to delete assessment question:', error);
        throw error;
    }
}

// Jobs
export const fetchJobs = async (id) => {
    try {
        const response = await apiCall('get', `/jobs/owner/${id}`);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch jobs:', error);
        throw error;
    }
}

export const createJob = async (data) => {
    try {
        const response = await apiCall('post', '/jobs/create', data);
        return response;
    } catch (error) {
        console.error('Failed to create job:', error);
        throw error;
    }
}

export const updateJobStatus = async (data) => {
    try {
        const response = await apiCall('put', `/jobs/job/status/${data.id}`, data);
        return response;
    } catch (error) {
        console.error('Failed to update job:', error);
        throw error;
    }
}

export const updateJob = async (data) => {
    try {
        const response = await apiCall('put', `/jobs/job/${data.id}`, data);
        return response;
    } catch (error) {
        console.error('Failed to update job status:', error);
        throw error;
    }
}

export const deleteJob = async (data) => {
    try {
        const response = await apiCall('delete', `/jobs/job/${data.id}`, data);
        return response;
    } catch (error) {
        console.error('Failed to delete job:', error);
        throw error;
    }
}

//Candidates
export const fetchCandidates = async (id) => {
    try {
        const response = await apiCall('get', `/candidates/owner/${id}`);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch candidates:', error);
        throw error;
    }
}

export const createCandidate = async (data) => {
    try {
        const response = await apiCall('post', '/candidates/create', data);
        return response;
    } catch (error) {
        console.error('Failed to create job candidate:', error);
        throw error;
    }
}

export const updateCandidate = async (data) => {
    try {
        const response = await apiCall('put', '/candidates/update', data);
        return response.data;
    } catch (error) {
        console.error('Failed to update candidate:', error);
        throw error;
    }
}

export const deleteCandidate = async (data) => {
    try {
        const response = await apiCall('delete', '/candidateAssessments/delete', data);
        return response;
    } catch (error) {
        console.error('Failed to delete candidate:', error);
        throw error;
    }
}

//Job Assessment

export const fetchJobAssessment = async (id) => {
    try {
        const response = await apiCall('get', `/jobAssessments/job/${id}`);
        return response.data;
    } catch (error) {
        console.error('Failed to fetch job assessment:', error);
        throw error;
    }
}

export const createJobAssessment = async (data) => {
    try {
        const response = await apiCall('post', `/jobAssessments/create`, data);
        return response.data;
    } catch (error) {
        console.error('Failed to create job assessment:', error);
        throw error;
    }
}

export const deleteJobAssessment = async (data) => {
    try {
        const response = await apiCall('delete', `/jobAssessments/delete`, data);
        return response.data;
    } catch (error) {
        console.error('Failed to delete job assessment:', error);
        throw error;
    }
}

// Candidate Results
export const fetchCanddiateResult = async (assessmentId,jobCandidateId) => {
    try {
        const response = await apiCall('get', `/assessments/results/${assessmentId}/${jobCandidateId}`);
        return response;
    } catch (error) {
        console.error('Failed to load candidate results:', error);
        throw error;
    }
}
import useGlobalStore from "../store/globalStore";
import useRecruiterStore from "../store/recruiterStore";
import useCandidateStore from "../store/candidateStore";
import useUserStore from "../store/userStore";

import apiCall from "../utils/apiCall";
import { fetchSkills } from "./adminService";
import { fetchAssessments, fetchJobs, fetchAssessmentQuestions, fetchAssessmentRequirements, fetchCandidates } from "./recruiterService";
import { fetchCandidateReferences, fetchWorkHistory, fetchReferenceInvitationsSent, fetchReceivedReferenceInvitations, fetchAssessmentInvitations } from "./candidateService";
import useExperimentStore from "../store/experimentStore";

const loadCandidateData = async (userId) => {
    try {
        const [
            assessmentInvitations,
            referenceInvitationsSent,
            references,
            workHistory,
            // candidateSkills,
            receivedInvitations,
            skills
        ] = await Promise.all([
            fetchAssessmentInvitations(userId),
            fetchReferenceInvitationsSent(userId),
            fetchCandidateReferences(userId),
            fetchWorkHistory(userId),
            // getCandidateSkills(userId),
            fetchReceivedReferenceInvitations(),
            fetchSkills()
        ]);

        // Update stores
        const candidateStore = useCandidateStore.getState();
        const experimentStore = useExperimentStore.getState();
        const adminStore = useGlobalStore.getState();
        //console.log(receivedInvitations)
        experimentStore.initializeRecruiterInvitations(assessmentInvitations);
        experimentStore.initializeSentInvitations(referenceInvitationsSent);
        console.log(typeof references)
        experimentStore.addFriends(references)
        //candidateStore.addTests(referenceInvitationsSent)        
        experimentStore.initializeUserReferences(references);
        experimentStore.initializeWorkHistory(workHistory);
        // candidateStore.addCandidateSkills(candidateSkills);
        experimentStore.initializeReceivedInvitations(receivedInvitations);
        adminStore.addSkills(skills);

        return true;
    } catch (error) {
        console.error('Error loading candidate data:', error);
        return false;
    }
};

export const loadRecruiterData = async (userId) => {
    try {
        const [
            jobs,
            assessmentQuestions,
            assessmentRequirements,
            assessments,
            candidates,
            //candidates,
            //appSkills
            skills
        ] = await Promise.all([
            fetchJobs(userId),
            fetchAssessmentQuestions(userId), 
            fetchAssessmentRequirements(userId),
            //fetchAssessmentSkills(userId),
            fetchAssessments(userId),
            fetchCandidates(userId),
            //fetchCandidates(userId),
            fetchSkills()
        ])
        const recruiterStore = useRecruiterStore.getState();
        const globalStore = useGlobalStore.getState();
        //const adminStore = useAdminStore.getState();
        recruiterStore.addJob(jobs);
        recruiterStore.addAssessment(assessments);
        recruiterStore.addAssessmentQuestion(assessmentQuestions);
        recruiterStore.addAssessmentRequirements(assessmentRequirements);
        recruiterStore.addJobCandidate(candidates);   
        //recruiterStore.addJobCandidate(candidates);
        //recruiterStore.addAssessmentSkill(assessmentSkills);
        globalStore.addSkills(skills);
        return true;
    } catch (error) {
        console.error('Error loading recruiter data:', error);
        return false;
    }
}

export const loginUser = async (data) => {
    try {
        const response = await apiCall('post', '/users/login', data);
        if (response.status !== 'error') {
            console.log(response);
            localStorage.setItem('authToken', response.data.token); // Store token in localStorage
            if (response.data.user.role === 'candidate') {
                await loadCandidateData(response.data.user.id);
            } else {
                await loadRecruiterData(response.data.user.id);
            }
            //const globalStore = useGlobalStore.getState();
            //globalStore.setLoading(false);
            //globalStore.setNotificationWithTimeout("hurra","success",3000)
            return response;
        } else {
            return false;
        }

    } catch (error) {
        console.error('Failed to sign in user:', error);
        return {message:"Login error",success:false};
    }
}

export const checkAndValidateToken = async () => {
    const token = localStorage.getItem('authToken');
    //const userStore = useStore.getState();
    console.log("check status")
    if (!token) {
        //userStore.setLoading(false);
        return { success: false, message: 'No token found' };
    }

    try {
        const response = await apiCall('get', '/users/verify-token');
        if (response.success && response.data.user) {
            if (response.data.user.role === 'candidate') {
                await loadCandidateData(response.data.user.id);
                return { success: true, user: response.data.user };
            } else {
                await loadRecruiterData(response.data.user.id);
                return { success: true, user: response.data.user };
            }
        } else {
            localStorage.removeItem('authToken');
            return { success: false, message: 'Invalid token' };
        }
    } catch (error) {
        console.error('Failed to validate token:', error);
        return { success: false, message: 'Validation error' };
    }
};

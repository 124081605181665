import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import logo from './../../../assets/brand/brand_logo.png';
import useUserStore from '../../../store/userStore';
import { loginUser } from '../../../services/authService';
import useGlobalStore from '../../../store/globalStore';
import Button from '../../../components/Buttons/Buttons';
import Link from '../../../components/Link/Link';
import { H2, H3 } from '../../../components/Typography/Typography';
import { Input } from '../../../components/Form/Form';

//import useAdminStore from '../../state/adminState';
function Login() {
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors, isValid } } = useForm({
        mode: 'onChange', // Enables validation on change
    });
    const { setUser, user } = useUserStore((state) => state);
    const { setAppNotificationWithTimeout, setNotification, setNotificationStatus, notification } = useGlobalStore((state) => state);
    // //const user = useRoleRedirect(); // This will redirect if the user is already authenticated

    // const { setNotificationWithTimeout} = useGlobalStore((state) => state);

    // const onLogin = async (data) => {
    //     const response = await loginUser(data);
    //     if(!response.success) {
    //         setNotificationWithTimeout('Login failed.','error',3000);
    //     } else {
    //         setUser(response.data.user); // Store data in Zustand
    //         setNotificationWithTimeout('Login successful.','success',3000)
    //     }      
    // };

    // useEffect(() => {
    //     // Redirect if the user is already logged in
    //     redirectIfTokenValid(navigate);
    //   }, [navigate]);
    const onLogin = async (data) => {
        const response = await loginUser(data);
        console.log(response)
        if (!response.success) {
            setAppNotificationWithTimeout('Login failed.', 'error', 'Your login attempt was unsuccessful.', 3000); // 3 seconds
        } else {
            setUser(response.data.user); // Store user data in Zustand
            setAppNotificationWithTimeout('Login successful.', 'success', 'Welcome back!', 3000);
            console.log(response)
            const userRole = response.data.user.role;
            if (userRole === 'candidate') {
                navigate('/candidate');
            } else if (userRole === 'recruiter') {
                navigate('/recruiter/dashboard');
            } else if (userRole === 'admin') {
                navigate('/admin');
            }            
        }

    };
    return (
        <div className="flex h-full min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <img
                    className="mx-auto h-10 w-auto"
                    src={logo}
                    alt="GoSkilling"
                />
                <H3
                    className={"text-center"}
                >
                    Sign in to your account
                </H3>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <form onSubmit={handleSubmit(onLogin)} className="space-y-6">
                    <Input
                        label="Email address"
                        id="email"
                        register={register}
                        required={true}
                        errors={errors}
                        type="email"
                    />

                    <Input
                        label="Password"
                        id="password"
                        register={register}
                        required={true}
                        errors={errors}
                        type="password"
                    />
                    <Link to="/forgot">
                        Forgot password?
                    </Link>


                    <div>
                        <Button
                            variant="primary"
                            size="medium"
                            className={"w-full"}
                            type="submit"
                            disabled={!isValid}
                        >
                            Sign in
                        </Button>
                        {/* <button
                            type="submit"
                            disabled={!isValid}
                            className="flex w-full justify-center rounded-md bg-brand-600 disabled:bg-brand-300 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-brand-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600"
                            >
                            Sign in
                        </button> */}
                    </div>
                </form>

                <p className="mt-10 text-center text-sm text-gray-500">
                    Want to improve hiring?{' '}
                    <Link to="/pricing" className="font-semibold leading-6 text-brand-600 hover:text-brand-500">
                        Contact Sales
                    </Link>
                </p>
            </div>
        </div>
    );
}

export default Login;

